<template>
  <div id="index__mobile">
    <modals-container />
    <section>
      <div v-if="mainBannerList" style="position: relative">
        <div class="embla embla_main">
          <div class="embla__container">
            <div
              class="embla__slide"
              v-for="(banner, i) of mainBannerList"
              :key="i"
            >
              <HomeLinkButton :link="banner.webLink">
                <img :src="banner.mobileImageUrl" width="100%" />
              </HomeLinkButton>
            </div>
          </div>
        </div>
        <div
          style="
            position: absolute;
            top: 8px;
            right: 8px;
            padding: 2px 8px;
            border-radius: 9999px;
            background: rgba(0, 0, 0, 0.3);
            font-size: 12px;
            font-weight: 400;
            letter-spacing: -0.24px;
            color: white;
            text-align: center;
            min-width: 26px;
          "
        >
          {{ mainBannerIndex }} / {{ mainBannerList.length }}
        </div>
      </div>
      <div
        v-else
        style="
          width: 1920px;
          height: 420px;
          margin-left: auto;
          margin-right: auto;
          background-color: #f5f5f5;
        "
      ></div>
    </section>
    <section style="padding: 24px 16px">
      <h1
        style="
          font-size: 22px;
          font-weight: 700;
          letter-spacing: -0.22px;
          color: #212121;
        "
      >
        <span style="color: #d7223c">초신선</span> 식재료 둘러보기
      </h1>
      <div
        class="list-category"
        style="
          margin-top: 16px;
          display: grid;
          grid-template-columns: repeat(5, 46px);
          padding: 0px 4px;
          row-gap: 16px;
          justify-content: space-between;
        "
      >
        <template v-for="(category, key) of categoryList">
          <button
            v-if="!category.closed"
            :key="key"
            @click="linkToList(category.category)"
            style="grid-column: span 1 / span 1"
          >
            <img
              v-if="category.event_thumbnail"
              :src="category.event_thumbnail"
              width="46px"
              height="46px"
              style="display: block; border-radius: 9999px"
            />
            <div
              v-else
              style="
                width: 46px;
                height: 46px;
                border-radius: 9999px;
                background-color: #f7f6f6;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <category-icon
                :category="category.icon"
                :width="30"
                :height="30"
                :iconColor="'#424242'"
              />
              <i
                v-if="category.isNewBadge"
                style="position: absolute; right: 1px; top: 1px"
                ><img
                  src="/assets/list/new-badge.svg"
                  width="20px"
                  height="20px"
                  style="display: block"
              /></i>
            </div>
            <p
              style="
                font-size: 12px;
                color: #424242;
                letter-spacing: -0.24px;
                font-weight: 400;
                text-align: center;
              "
            >
              {{ category.name }}
            </p>
          </button>
          <a v-else :key="key" style="cursor: default">
            <div style="background-color: #f0fbfb">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="22"
                viewBox="0 0 24 22"
                fill="none"
              >
                <g clip-path="url(#clip0_1281_19523)">
                  <path
                    d="M19.8562 10.0616C19.8562 6.56341 17.5579 3.59361 14.392 2.5783C14.3597 1.28839 13.3028 0.25 12.006 0.25C10.7092 0.25 9.65693 1.28608 9.62232 2.57138C6.44716 3.57977 4.13963 6.55648 4.13963 10.0616L4.14886 13.6337L2.49666 16.495C2.46205 16.555 2.46205 16.6312 2.49666 16.6912L3.14969 17.8219C3.18431 17.8818 3.24892 17.9211 3.32045 17.9211H8.59547C8.5747 18.0757 8.56086 18.2303 8.56086 18.3895C8.56086 20.2909 10.1069 21.837 12.0083 21.837C13.6213 21.837 14.9781 20.7201 15.3496 19.2202H13.9789C13.6144 20.0786 12.7052 20.6532 11.6876 20.5055C10.6699 20.3578 9.9523 19.541 9.87615 18.5626C9.85769 18.3434 9.87615 18.1288 9.91999 17.9234H20.6962C20.7654 17.9234 20.83 17.8865 20.8669 17.8242L21.52 16.6935C21.5546 16.6335 21.5546 16.5573 21.52 16.4973L19.8678 13.6337L19.8585 10.0616H19.8562ZM12.0083 1.55837C12.4744 1.55837 12.869 1.85835 13.019 2.27371C12.6844 2.22986 12.3452 2.20448 11.9991 2.20448C11.6529 2.20448 11.3253 2.22755 10.9976 2.2714C11.1476 1.85835 11.5422 1.55837 12.0083 1.55837ZM3.97348 16.5942L5.47799 13.989L5.46876 10.0616C5.46876 6.46188 8.39702 3.53131 11.9991 3.53131C15.6011 3.53131 18.5294 6.45957 18.5294 10.0639L18.5386 13.9914L20.0408 16.5942H3.97348Z"
                    fill="#29C2CE"
                  />
                  <path
                    d="M21.6447 4.80013L21.264 4.26709L20.2002 5.02858L20.5809 5.56162C21.3863 6.68769 21.8247 8.0699 21.8132 9.4475L21.8085 10.1005L23.1169 10.1098L23.1215 9.45673C23.1331 7.80223 22.6093 6.14773 21.6447 4.80013Z"
                    fill="#29C2CE"
                  />
                  <path
                    d="M3.41871 5.56162L3.79945 5.02858L2.73568 4.26709L2.35494 4.80013C1.39039 6.15004 0.866579 7.80454 0.878116 9.45904L0.882731 10.1121L2.1911 10.1028L2.18649 9.44981C2.17495 8.0699 2.61338 6.69 3.41871 5.56392V5.56162Z"
                    fill="#29C2CE"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1281_19523">
                    <rect
                      width="22.2446"
                      height="21.587"
                      fill="white"
                      transform="translate(0.87793 0.25)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p
                style="
                  font-size: 10px;
                  font-weight: 600;
                  letter-spacing: -0.2px;
                  color: #29c2ce;
                  margin-top: 2.6px;
                "
              >
                {{ category.closed_message }}
              </p>
            </div>
            <p
              style="
                font-size: 12px;
                color: #424242;
                letter-spacing: -0.24px;
                font-weight: 400;
                text-align: center;
              "
            >
              {{ category.name }}
            </p>
          </a>
        </template>
      </div>
    </section>
    <div v-if="curationList" style="margin-bottom: 40px">
      <div v-for="(curation, i) of curationList" :key="i">
        <div
          v-if="curation.type === 'home-curation-carousel-image'"
          style="padding: 24px 16px"
        >
          <HomeLinkButton :link="curation.data.webLink">
            <img
              :src="curation.data.mobileImageUrl"
              width="100%"
              style="display: block; margin-bottom: 16px"
            />
          </HomeLinkButton>
          <div style="position: relative">
            <div :class="`embla embla${i}`">
              <div class="embla__container" style="gap: 8px">
                <div
                  class="embla__slide"
                  v-for="(item, j) in curation.data.items"
                  :key="j"
                  style="flex: 0 0 140px"
                >
                  <div
                    @click="linkToDetail(item.id)"
                    style="grid-column: span 1 / span 1; cursor: pointer"
                  >
                    <div
                      :style="`width: 140px; height: 140px; display: flex; justify-content: center; align-items: center; background-color: #f5f5f5; border-radius: 6px; position: relative;`"
                    >
                      <div
                        v-if="item.block_cart"
                        style="
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          background: rgba(0, 0, 0, 0.36);
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          z-index: 5;
                          border-radius: 8px;
                        "
                      >
                        <p
                          style="
                            color: white;
                            font-size: 16px;
                            font-weight: 700;
                          "
                        >
                          일시품절
                        </p>
                      </div>
                      <img
                        :src="item.list_thumbnail"
                        width="100px"
                        height="100px"
                      />
                      <img
                        @click="openCartDialog(item, userEmail, $event)"
                        src="/assets/eventDetail/template/cart.svg"
                        width="40px"
                        height="40px"
                        style="
                          filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.04));
                          cursor: pointer;
                          position: absolute;
                          bottom: 6px;
                          right: 6px;
                        "
                      />
                    </div>
                    <div style="margin-top: 8px">
                      <div v-if="item.list_tag" style="margin-bottom: 6px">
                        <div
                          style="
                            padding: 3px 4px;
                            border-radius: 2px;
                            font-size: 10px;
                            font-weight: 500;
                            color: white;
                            background-color: #06b8c8;
                            border: 1px solid #06b8c8;
                            display: inline-block;
                          "
                        >
                          {{ item.list_tag }}
                        </div>
                      </div>
                      <p :style="`font-size: 13px; color: #212121;`">
                        {{ item.name }} {{ item.usage }} {{ item.weight
                        }}{{ item.weighttype }}
                      </p>
                      <div style="margin-top: 6px">
                        <p
                          v-if="item.preprice"
                          style="
                            margin-bottom: 2px;
                            text-decoration: line-through;
                            color: rgba(33, 33, 33, 0.4);
                            font-size: 12px;
                            font-weight: 400;
                          "
                        >
                          {{ item.preprice | priceForm }}원
                        </p>
                        <p
                          style="
                            font-size: 16px;
                            font-weight: 600;
                            display: flex;
                            gap: 2px;
                          "
                        >
                          <span style="color: #f02c49" v-if="item.preprice"
                            >{{
                              Math.floor(
                                (1 - item.price / item.preprice).toFixed(3) *
                                  100,
                              )
                            }}%</span
                          >
                          <span style="color: #212121"
                            >{{ item.price | priceForm }}원</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="curation.type === 'home-eventbanner-primary'"
          style="padding: 24px 16px"
        >
          <div
            style="
              width: 100%;
              height: 90px;
              padding: 0px 16px;
              box-sizing: border-box;
              border-radius: 6px;
              background: #f2fcfd;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 24px;
            "
          >
            <img
              :src="curation.data.mobileImageUrl"
              width="66px"
              height="66px"
            />
            <div style="flex: 1">
              <p
                style="
                  color: #616161;
                  opacity: 0.7;
                  font-size: 12px;
                  font-weight: 400;
                  letter-spacing: -0.12px;
                "
              >
                {{ curation.data.subtitle }}
              </p>
              <h3
                style="
                  margin-top: 6px;
                  color: #212121;
                  font-size: 15px;
                  font-weight: 600;
                  letter-spacing: -0.15px;
                "
              >
                {{ curation.data.mobileTitle }}
              </h3>
            </div>
            <HomeLinkButton :link="curation.data.webLink">
              <span
                style="
                  width: 39px;
                  height: 28px;
                  border-radius: 6px;
                  background: #daf4f7;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #05a6b4;
                  font-weight: 500;
                  font-size: 13px;
                "
              >
                보기
              </span>
            </HomeLinkButton>
          </div>
        </div>

        <div
          v-if="curation.type === 'home-curation-carousel-basic'"
          style="padding: 24px 16px"
        >
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 8px;
            "
          >
            <h2
              style="
                color: #212121;
                font-size: 20px;
                font-weight: 700;
                letter-spacing: -0.4px;
              "
            >
              {{ curation.data.title }}
            </h2>
            <HomeLinkButton :link="curation.data.webLink">
              <div
                style="
                  display: flex;
                  align-items: center;
                  gap: 2px;
                  padding: 6px 8px;
                "
              >
                <p
                  style="
                    font-size: 13px;
                    letter-spacing: -0.13px;
                    font-weight: 500;
                    color: #757575;
                  "
                >
                  전체보기
                </p>
                <img
                  src="/assets/index/mobile/redirect-arrow.svg"
                  width="16px"
                  height="16px"
                />
              </div>
            </HomeLinkButton>
          </div>
          <p
            style="
              margin-top: 4px;
              color: #757575;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: -0.28px;
            "
          >
            {{ curation.data.description }}
          </p>

          <div style="position: relative; margin-top: 16px">
            <div :class="`embla embla${i}`">
              <div class="embla__container" style="gap: 8px">
                <div
                  class="embla__slide"
                  v-for="(item, j) in curation.data.items"
                  :key="j"
                  style="flex: 0 0 140px"
                >
                  <div
                    @click="linkToDetail(item.id)"
                    style="grid-column: span 1 / span 1; cursor: pointer"
                  >
                    <div
                      :style="`width: 140px; height: 140px; display: flex; justify-content: center; align-items: center; background-color: #f5f5f5; border-radius: 6px; position: relative;`"
                    >
                      <div
                        v-if="item.block_cart"
                        style="
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          background: rgba(0, 0, 0, 0.36);
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          z-index: 5;
                          border-radius: 8px;
                        "
                      >
                        <p
                          style="
                            color: white;
                            font-size: 16px;
                            font-weight: 700;
                          "
                        >
                          일시품절
                        </p>
                      </div>
                      <img
                        :src="item.list_thumbnail"
                        width="100px"
                        height="100px"
                      />
                      <img
                        @click="openCartDialog(item, userEmail, $event)"
                        src="/assets/eventDetail/template/cart.svg"
                        width="40px"
                        height="40px"
                        style="
                          filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.04));
                          cursor: pointer;
                          position: absolute;
                          bottom: 6px;
                          right: 6px;
                        "
                      />
                    </div>
                    <div style="margin-top: 8px">
                      <div v-if="item.list_tag" style="margin-bottom: 6px">
                        <div
                          style="
                            padding: 3px 4px;
                            border-radius: 2px;
                            font-size: 10px;
                            font-weight: 500;
                            color: white;
                            background-color: #06b8c8;
                            border: 1px solid #06b8c8;
                            display: inline-block;
                          "
                        >
                          {{ item.list_tag }}
                        </div>
                      </div>
                      <p :style="`font-size: 13px; color: #212121;`">
                        {{ item.name }} {{ item.usage }} {{ item.weight
                        }}{{ item.weighttype }}
                      </p>
                      <div style="margin-top: 6px">
                        <p
                          v-if="item.preprice"
                          style="
                            margin-bottom: 2px;
                            text-decoration: line-through;
                            color: rgba(33, 33, 33, 0.4);
                            font-size: 12px;
                            font-weight: 400;
                          "
                        >
                          {{ item.preprice | priceForm }}원
                        </p>
                        <p
                          style="
                            font-size: 16px;
                            font-weight: 600;
                            display: flex;
                            gap: 2px;
                          "
                        >
                          <span style="color: #f02c49" v-if="item.preprice"
                            >{{
                              Math.floor(
                                (1 - item.price / item.preprice).toFixed(3) *
                                  100,
                              )
                            }}%</span
                          >
                          <span style="color: #212121"
                            >{{ item.price | priceForm }}원</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="curation.type === 'home-curation-single'"
          style="
            padding: 24px 16px;
            margin-top: 24px;
            margin-bottom: 24px;
            background-color: #f2fafb;
          "
        >
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 8px;
            "
          >
            <h2
              style="
                color: #212121;
                font-size: 20px;
                font-weight: 700;
                letter-spacing: -0.4px;
              "
            >
              {{ curation.data.title }}
            </h2>
            <HomeLinkButton :link="curation.data.webLink">
              <div
                style="
                  display: flex;
                  align-items: center;
                  gap: 2px;
                  padding: 6px 8px;
                "
              >
                <p
                  style="
                    font-size: 13px;
                    letter-spacing: -0.13px;
                    font-weight: 500;
                    color: #757575;
                  "
                >
                  전체보기
                </p>
                <img
                  src="/assets/index/mobile/redirect-arrow.svg"
                  width="16px"
                  height="16px"
                />
              </div>
            </HomeLinkButton>
          </div>
          <p
            style="
              margin-top: 4px;
              color: #757575;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: -0.28px;
            "
          >
            {{ curation.data.description }}
          </p>

          <div style="margin-top: 16px">
            <img :src="curation.data.mobileImageUrl" width="100%" />
            <div
              style="
                display: flex;
                gap: 16px;
                align-items: center;
                margin-top: 16px;
              "
            >
              <a
                :href="`/detail?product=${curation.linkValue.goodslistkey}`"
                style="
                  width: 126px;
                  height: 126px;
                  border-radius: 6px;
                  background-color: #ffffff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <img
                  :src="curation.data.listitem.list_thumbnail"
                  width="96px"
                  height="96px"
                />
              </a>
              <a
                :href="`/detail?product=${curation.linkValue.goodslistkey}`"
                style="flex: 1"
              >
                <div
                  v-if="curation.data.listitem.list_tag"
                  style="margin-bottom: 6px"
                >
                  <div
                    style="
                      padding: 3px 4px;
                      border-radius: 2px;
                      font-size: 10px;
                      font-weight: 500;
                      color: white;
                      background-color: #06b8c8;
                      border: 1px solid #06b8c8;
                      display: inline-block;
                    "
                  >
                    {{ curation.data.listitem.list_tag }}
                  </div>
                </div>
                <p style="font-size: 14px; color: #212121; font-weight: 400">
                  {{ curation.data.listitem.name }}
                </p>
                <p
                  style="
                    margin-top: 2px;
                    color: #616161;
                    font-size: 12px;
                    font-weight: 400;
                  "
                >
                  <span v-if="curation.data.listitem.usage"
                    >{{ curation.data.listitem.usage }} / </span
                  >{{ curation.data.listitem.weight
                  }}{{ curation.data.listitem.weighttype }}
                </p>
                <div style="margin-top: 6px">
                  <p
                    v-if="curation.data.listitem.preprice"
                    style="
                      margin-bottom: 2px;
                      text-decoration: line-through;
                      color: rgba(33, 33, 33, 0.4);
                      font-size: 12px;
                      font-weight: 400;
                    "
                  >
                    {{ curation.data.listitem.preprice | priceForm }}원
                  </p>
                  <p
                    style="
                      font-size: 16px;
                      font-weight: 600;
                      display: flex;
                      gap: 2px;
                    "
                  >
                    <span
                      style="color: #f02c49"
                      v-if="curation.data.listitem.preprice"
                      >{{
                        Math.floor(
                          (
                            1 -
                            curation.data.listitem.price /
                              curation.data.listitem.preprice
                          ).toFixed(3) * 100,
                        )
                      }}%</span
                    >
                    <span style="color: #212121"
                      >{{ curation.data.listitem.price | priceForm }}원</span
                    >
                  </p>
                </div>
              </a>
              <img
                @click="
                  openCartDialog(curation.data.listitem, userEmail, $event)
                "
                src="/assets/eventDetail/template/cart.svg"
                width="40px"
                height="40px"
                style="
                  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.04));
                  cursor: pointer;
                  margin-top: auto;
                  margin-bottom: 8px;
                "
              />
            </div>
          </div>
        </div>

        <div
          v-if="curation.type === 'home-curation-list'"
          style="padding: 24px 16px"
        >
          <h2
            style="
              color: #212121;
              font-size: 20px;
              font-weight: 700;
              letter-spacing: -0.4px;
            "
          >
            {{ curation.data.title }}
          </h2>
          <p
            style="
              margin-top: 4px;
              color: #757575;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: -0.28px;
            "
          >
            {{ curation.data.description }}
          </p>

          <img
            :src="curation.data.mobileImageUrl"
            width="100%"
            style="margin-top: 16px; display: block"
          />

          <ul style="margin-top: 16px">
            <li
              v-for="(item, j) in curation.data.items"
              :key="j"
              style="
                display: flex;
                gap: 16px;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div
                style="
                  width: 126px;
                  height: 126px;
                  border-radius: 6px;
                  background-color: #f5f5f5;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <img :src="item.list_thumbnail" width="96px" height="96px" />
              </div>
              <div style="flex: 1">
                <div v-if="item.list_tag" style="margin-bottom: 6px">
                  <div
                    style="
                      padding: 3px 4px;
                      border-radius: 2px;
                      font-size: 10px;
                      font-weight: 500;
                      color: white;
                      background-color: #06b8c8;
                      border: 1px solid #06b8c8;
                      display: inline-block;
                    "
                  >
                    {{ item.list_tag }}
                  </div>
                </div>
                <p style="font-size: 14px; color: #212121; font-weight: 400">
                  {{ item.name }}
                </p>
                <p
                  style="
                    margin-top: 2px;
                    color: #616161;
                    font-size: 12px;
                    font-weight: 400;
                  "
                >
                  <span v-if="item.usage">{{ item.usage }} / </span
                  >{{ item.weight }}{{ item.weighttype }}
                </p>
                <div style="margin-top: 6px">
                  <p
                    v-if="item.preprice"
                    style="
                      margin-bottom: 2px;
                      text-decoration: line-through;
                      color: rgba(33, 33, 33, 0.4);
                      font-size: 12px;
                      font-weight: 400;
                    "
                  >
                    {{ item.preprice | priceForm }}원
                  </p>
                  <p
                    style="
                      font-size: 16px;
                      font-weight: 600;
                      display: flex;
                      gap: 2px;
                    "
                  >
                    <span style="color: #f02c49" v-if="item.preprice"
                      >{{
                        Math.floor(
                          (1 - item.price / item.preprice).toFixed(3) * 100,
                        )
                      }}%</span
                    >
                    <span style="color: #212121"
                      >{{ item.price | priceForm }}원</span
                    >
                  </p>
                </div>
              </div>
              <img
                @click="openCartDialog(item, userEmail, $event)"
                src="/assets/eventDetail/template/cart.svg"
                width="40px"
                height="40px"
                style="
                  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.04));
                  cursor: pointer;
                  margin-top: auto;
                  margin-bottom: 8px;
                "
              />
            </li>
          </ul>

          <HomeLinkButton :link="curation.data.webLink">
            <div class="redirect-button">
              <p style="font-size: 13px; font-weight: 500">상품 전체보기</p>
              <img
                src="/assets/index/redirect-arrow.svg"
                width="16px"
                height="16px"
              />
            </div>
          </HomeLinkButton>
        </div>
      </div>
    </div>

    <div
      @click="closePurchaseFloating()"
      v-if="detailFloatingFlag"
      class="detail-purchase__floating-backdrop"
    ></div>

    <div v-if="detailFloatingFlag" class="detail-purchase__floating">
      <section v-if="detailFloatingFlag" @click="optionDropdownControl($event)">
        <div class="detail-purchase__option-dropdown">
          <button
            id="detail-purchase__option-dropdown-btn"
            class="detail-purchase__option-dropdown-btn"
          >
            {{ addToCartProduct.selects[optionSelected].name
            }}<i id="detail-purchase__option-dropdown-arrow"></i>
          </button>
          <div
            v-if="optionDropdownFlag === true"
            class="detail-purchase__option-dropdown-content"
          >
            <div
              class="detail-purchase__option-dropdown-list"
              v-for="(option, index) of addToCartProduct.selects"
              :key="index"
            >
              <div @click="optionSelect(index)">{{ option.name }}</div>
            </div>
          </div>
        </div>
        <div class="detail-purchase__counter">
          <button
            @click="itemCountChange(-1)"
            class="detail-purchase__counter-btn"
          >
            <i class="detail-purchase__counter-minus"></i>
          </button>
          <div class="detail-purchase__counter-number">{{ itemCount }}</div>
          <button
            @click="itemCountChange(1)"
            class="detail-purchase__counter-btn"
          >
            <i class="detail-purchase__counter-plus"></i>
          </button>
        </div>

        <h6 class="detail-purchase__price-title">기준가</h6>
        <div class="detail-purchase__price">
          <p
            v-if="addToCartProduct.preprice"
            class="detail-purchase__price-pre"
          >
            {{ addToCartProduct.preprice | priceForm }}원
          </p>
          <p class="detail-purchase__price-price">
            {{ addToCartProduct.price | priceForm }}원
          </p>
        </div>

        <div class="detail-purchase__btn-area">
          <button @click="addToCart('now')" btncolor="white">바로구매</button>
          <button @click="addToCart('cart')" btncolor="red">장바구니</button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Index from './Index.vue'
import { auth, db } from '../config/firebaseConfig'
import bus from '../utils/bus'
import { getCookie } from '../lib/cookie'
import { serverTimeDate } from '../return/serverTimeDate'

export default {
  name: 'IndexMobile',
  extends: Index,

  data() {
    return {
      detailFloatingFlag: false,
      addToCartProduct: null,
      optionSelected: 0,
      itemCount: 1,
      optionDropdownFlag: false,
    }
  },

  methods: {
    openCartDialog(target, userEmail, event = null) {
      if (event) event.stopPropagation()
      if (auth.currentUser) {
        this.addToCartProduct = target
        this.detailFloatingFlag = true
      } else {
        this.$router.push('/login_m')
      }
    },

    closePurchaseFloating() {
      this.detailFloatingFlag = false
      this.itemCount = 1
      this.optionSelected = 0
    },

    optionDropdownControl(event) {
      if (
        event.target.id === '' ||
        event.target.id.indexOf('detail-review__') !== -1
      ) {
        this.optionDropdownFlag = false
      } else {
        this.optionDropdownFlag = !this.optionDropdownFlag
      }
    },

    optionSelect(target) {
      this.optionSelected = target
    },

    itemCountChange(change) {
      if (this.itemCount + change > 0 && this.itemCount + change < 100) {
        this.itemCount += change
      }
    },

    addToCart(type) {
      if (auth.currentUser) {
        const goodsDetail = this.$store.state.goodsDetail.find(
          (x) =>
            x.unique_key ===
            this.addToCartProduct.selects[this.optionSelected].pid +
              '_' +
              this.addToCartProduct.selects[this.optionSelected].pop,
        )
        const isPopupGoods = goodsDetail.popupgoods ? true : false

        db.ref(
          'customers/' +
            auth.currentUser.uid +
            (isPopupGoods ? '/popupcart' : '/cart') +
            '/' +
            this.addToCartProduct.selects[this.optionSelected].pid +
            '_' +
            this.addToCartProduct.selects[this.optionSelected].pop,
        ).once('value', (cartSnaps) => {
          let cartSetCount = this.itemCount
          if (cartSnaps.val()) cartSetCount += cartSnaps.val().count

          db.ref(
            'customers/' +
              auth.currentUser.uid +
              (isPopupGoods ? '/popupcart' : '/cart') +
              '/' +
              this.addToCartProduct.selects[this.optionSelected].pid +
              '_' +
              this.addToCartProduct.selects[this.optionSelected].pop,
          ).set({
            count: cartSetCount,
            timestamp: new Date().toISOString(),
          })

          bus.$emit('add:cart')

          if (type === 'now') {
            this.$router.push('/cart')
            ga(
              'send',
              'event',
              'cart',
              'step02_btn_buynow',
              this.addToCartProduct.selects[this.optionSelected].pid,
            )
          } else {
            ga(
              'send',
              'event',
              'cart',
              'step02_btn_addcart',
              this.addToCartProduct.selects[this.optionSelected].pid,
            )
          }

          const fbEventId = getCookie('_ga') + '_' + serverTimeDate().getTime()
          fbq(
            'track',
            'AddToCart',
            {
              content_ids: [
                this.addToCartProduct.selects[this.optionSelected].pid,
              ],
              content_type: 'product',
              value: this.addToCartProduct.price * this.itemCount,
              currency: 'KRW',
            },
            {
              eventID: fbEventId,
            },
          )

          fbPixel(
            'AddToCart',
            {
              content_ids: [
                this.addToCartProduct.selects[this.optionSelected].pid,
              ],
              content_type: 'product',
              value: this.addToCartProduct.price * this.itemCount,
              currency: 'KRW',
            },
            fbEventId,
          )

          if (!wcs_add) var wcs_add = {}
          wcs_add['wa'] = 's_313ececb53dd'
          var _nasa = {}
          if (window.wcs) {
            _nasa['cnv'] = wcs.cnv('3', '1') // 전환유형, 전환가치 설정해야함. 설치매뉴얼 참고
            // eslint-disable-next-line
            wcs_do(_nasa)
          }
        })
      } else {
        this.$router.push('/login_m')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#index__mobile {
  * {
    font-family: 'Pretendard';
  }
  .embla {
    overflow: hidden;
  }

  .embla__container {
    display: flex;
  }

  .embla__slide {
    flex: 0 0 auto;
    min-width: 0;
    max-width: 100%;
  }

  .redirect-button {
    display: flex;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;

    &:active {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  .list-category {
    button {
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  .detail-purchase__floating-backdrop {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 11;
    top: 0px;
  }

  .detail-purchase__floating {
    width: 100%;
    height: 297px;
    background-color: #fff;
    border-top: 2px solid #ccc;
    z-index: 15;
    bottom: calc(constant(safe-area-inset-bottom) + 56px);
    bottom: calc(env(safe-area-inset-bottom) + 56px);
    position: fixed;

    .detail-purchase__floating-button {
      width: 92%;
      margin-left: 4%;
      border: none;
      border-radius: 5px;
      color: #fff;
      height: 50px;
      font-size: 18px;
      font-weight: bold;
      margin-top: 15px;
    }

    .detail-purchase__option-dropdown {
      width: 92%;
      border: 1px solid #000;
      margin-left: 4%;
      position: relative;
      z-index: 1;
      margin-top: 25px;

      .detail-purchase__option-dropdown-btn {
        width: 100%;
        height: 50px;
        font-size: 14px;
        font-weight: bold;
        position: relative;
        text-align: left;
        padding-left: 19px;
        background-color: white;
        color: black;
        border: none;

        #detail-purchase__option-dropdown-arrow {
          display: block;
          width: 24px;
          height: 24px;
          background: url(/assets/detailMobile/arrow.svg) no-repeat;
          position: absolute;
          bottom: 12px;
          right: 12px;
        }
      }

      .detail-purchase__option-dropdown-content {
        background-color: white;
        z-index: 1;
        position: absolute;
        width: 100%;
        border: 1px solid black;
        margin-left: -1px;

        .detail-purchase__option-dropdown-list {
          height: 43px;
          line-height: 43px;
          font-size: 15px;
          padding-left: 19px;
          cursor: pointer;
        }
      }
    }

    .detail-purchase__counter {
      margin-top: 10px;
      width: 92%;
      margin-left: 4%;
      height: 48px;
      overflow: hidden;
      border: 1px solid #cecece;

      .detail-purchase__counter-btn {
        width: 50px;
        height: 48px;
        border: none;
        background-color: #f3f3f3;
        float: left;

        .detail-purchase__counter-minus {
          width: 22px;
          height: 2px;
          display: block;
          margin-left: auto;
          margin-right: auto;
          background: url(/assets/detailMobile/minus.svg) content-box;
        }

        .detail-purchase__counter-plus {
          width: 20px;
          height: 20px;
          display: block;
          margin-left: auto;
          margin-right: auto;
          background: url(/assets/detailMobile/plus.svg) content-box;
        }
      }

      .detail-purchase__counter-number {
        float: left;
        width: calc(100% - 102px);
        border-left: 1px solid #cecece;
        border-right: 1px solid #cecece;
        line-height: 48px;
        text-align: center;
      }
    }

    .detail-purchase__price-title {
      width: 92%;
      margin-left: 4%;
      margin-top: 15px;
      text-align: right;
      font-size: 14px;
      line-height: 22px;
      color: #9b9b9b;
    }

    .detail-purchase__price {
      margin-top: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: end;
      margin-right: 4%;

      .detail-purchase__price-pre {
        font-size: 18px;
        line-height: 15px;
        font-weight: 400;
        color: #9e9e9e;
        text-decoration: line-through;
      }

      .detail-purchase__price-price {
        font-size: 28px;
        line-height: 22px;
        font-weight: bold;
      }
    }

    .detail-purchase__btn-area {
      overflow: hidden;
      width: 92%;
      margin-left: 4%;
      margin-top: 23px;

      button {
        width: 48.5%;
        height: 50px;
        font-size: 15px;
        font-weight: bold;
        float: left;

        &[btncolor='white'] {
          background-color: white;
          border: 2px solid #d0021b;
          border-radius: 5px;
          color: #d0021b;
        }

        &[btncolor='red'] {
          background-color: #d0021b;
          border: none;
          border-radius: 5px;
          color: white;
          margin-left: 3%;
        }
      }
    }
  }
}
</style>
